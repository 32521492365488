import React from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import ContentBlock from '../../components/contentBlock/contentBlock';
import Button from '../../components/button/button';
import AnimationWrapper from '../../globals/animationWrapper/animationWrapper';
import BreadCrumb from '../../globals/breadcrumb/breadcrumb';
import Image from '../../components/image/image';
import Wistia from '../../components/wistiaVideo/wistiaVideo';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import Link from '../../components/link/link';

/**
 * CMS Component: DatoCmsModuleHero
 * 
 * Four Versions: Short, Medium, Tall, Full
 * 
 */

const Hero = ({children, className, data, eyebrow,countdown,countdownHead,headingTag, location, size, ...other}) => {
  const { backgroundImage, cta, heroSize, wistiaId} = data || {};
  //Eyebrow fallbacks
  let eyebrowContent = ((cta && cta.length > 0 && cta[0]?.eyebrow) && <p>{cta[0]?.eyebrow}</p>) || eyebrow || <BreadCrumb colorprofile="Reversed" className={`tablet-min`} location={location}/>;
  //Remove eyebrow from cta data
  let {eyebrow : ctaEyebrow, ...ctaClean} = (cta && cta.length > 0) ? cta[0] : {};
  let finalSize = heroSize || size;

  const nextSection = (event) => {
    var bodyRect = document.body.getBoundingClientRect(),
    elemRect = event.target.closest('section').getBoundingClientRect(),
    offset = elemRect.bottom - bodyRect.top;
    window.scrollTo(0, offset);
    setTimeout(function() { // patch animation
      window.scrollTo(0, offset);
    }, 200); // how long to wait before patching
  }
  
  return (
    <section className={`hero hero--${finalSize} ${className || ''}`} {...other} data-addsearch="include" >
      { wistiaId && <Wistia id={data.wistiaId} quality="1080" className="backgroundMedia" controls={false} videoType="Single" /> }
      {
        backgroundImage && <Image lazyload={false} data={backgroundImage} width="100vw" className="backgroundMedia" />
      }
      <div className="container">
        {
          eyebrowContent && 
          <div className="hero__eyebrow" >
            {eyebrowContent}
          </div>  
        }
        <div className="hero__content">
          {
            children
          }
          {
            ctaClean?.heading && <ContentBlock headingTag={headingTag} data={ ctaClean } linkclass={`${finalSize === 'Tall' ? 'btn--primary' : 'link--featured--primary' }`} className={`Reversed`} />
          }
        </div>
      </div>
      {ctaClean?.countdown && (
  <div className="hero__content_counter">
<CountdownTimer
  className="countdown_main"
  countdownHead={ctaClean.countdownHead}
/>
  </div>
)}
      {
        finalSize === "Full" &&
        <>
          <Button className="btn--circle--dark--large" onClick={nextSection} />
          <AnimationWrapper animationtype="expand-up" className={`absolute`}/>
        </>
      }

    </section>
  );
};

Hero.propTypes = {
  /**
  * Insert any content (placement above heading)
  */
  children: PropTypes.any,
  /**
  * Classname
  */
  className: PropTypes.string,
  /**
  * Data from cms
  */
  data : PropTypes.shape({
    backgroundImage: PropTypes.object ,
    cta: PropTypes.arrayOf(PropTypes.object) ,
    heroSize: PropTypes.oneOf(['', 'Short', 'Medium', 'Tall', 'Full']),
    wistiaId: PropTypes.string
  }),
  /**
  * optional eyebrow override (default is breadcrumb)
  */
  eyebrow: PropTypes.any,
  /**
  * Type of heading
  */
  headingTag: PropTypes.oneOf(['h1', 'h2']),
 /**
  * location object data
  */
  location: PropTypes.object,
  /**
  * hero size
  */
   countdownHead: PropTypes.any,
  size: PropTypes.oneOf(['','Short', 'Medium', 'Tall', 'Full'])
};

Hero.defaultProps = {
  headingTag: 'h1',
  countdownHead: null,
  size: 'Short'
};

export default Hero;

export const HeroFragment = graphql`
fragment HeroFragment on DatoCmsModuleHero {
  backgroundImage{
    ...BackgroundImageFragment
  }
  cta{
    countdown
    countdownHead
    ...EyebrowHeadingCopyLinkFragment
  }
  heroSize
  wistiaId
}
`;
